import * as React from "react";
import debounce from "lodash/debounce";

export const variantTypes = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
};

export const subVariantTypes = {
  BRAND: "brand",
  WARNING: "warning",
  SUCCESS: "success",
};

const Button = ({
  variant = variantTypes.SECONDARY,
  subVariant = subVariantTypes.BRAND,
  size = "medium",
  children,
  onClick,
  isDisable = false,
  isFullWidth,
  type = "button",
  customCss,
  delay = 30,
  disabledHoverText = "Not Allowed",
  ...props
}) => {
  const buttonTypeCss = (buttonType, subType) => {
    if (isDisable) {
      return "bg-gray-1010 text-gray-1050";
    }
    switch (`${buttonType}-${subType}`) {
      case "primary-brand":
        return "text-white bg-primary hover:bg-blue-1040 active:bg-blue-1040";
      case "secondary-brand":
        return "text-gray-1050 bg-white border border-gray-1010 hover:bg-gray-1200 active:bg-gray-1030 active:border-gray-1200";
      case "tertiary-brand":
        return "text-blue-300 hover:bg-blue-1030 active:bg-blue-1050";
      case "primary-warning":
        return "text-white bg-red-1200 hover:bg-red-1240 active:bg-red-1250";
      case "secondary-warning":
        return "text-red-1260 border border-red-1240";
      case "tertiary-warning":
        return "text-red-1260 hover:bg-red-1220 active:bg-red-1230";
      case "primary-success":
        return "text-white bg-[#5cb85c] hover:bg-[#4cae4c]";
      case "secondary-success":
        return "border border-[#527C43] text-[#527C43]";
      default:
        return "";
    }
  };

  // debouncing to avoid button thrashing
  const onClickHandler = debounce((e) => {
    if (!isDisable && onClick) {
      onClick(e);
    }
  }, delay);

  return (
    <button
      title={isDisable ? disabledHoverText : ""}
      type="button"
      className={[
        "rounded-sm py-9px px-16px focus:outline-none",
        buttonTypeCss(variant, subVariant),
        // buttonSizeCss(size),
        isDisable ? "cursor-not-allowed" : "cursor-pointer",
        isFullWidth && "w-full",
        customCss ? customCss : "",
      ].join(" ")}
      onClick={onClickHandler}
      disabled={isDisable}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
